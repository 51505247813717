<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="employees-list-controls">
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>
    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>
    <div class="table-header">
      Cantidad de personal: <b>{{ employees.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="employees"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="employeesVacationsList"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.NOMBRE}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="OBSERVACIONES"
      >
        <template #empty>
          <div class="has-text-centered">No hay empleados</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <p v-if="!checkIfValid(props.row[column.field])">
              {{ "Sin información" }}
            </p>
            <span
              v-if="checkIfValid(props.row[column.field]) && column.money"
              >{{ props.row[column.field] | money("MXN", 2) }}</span
            >
            <span
              v-if="checkIfValid(props.row[column.field]) && !column.money"
              >{{ props.row[column.field] }}</span
            >
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="openAddEmpoloyeeVacations(props.row)"
                    >Agregar vacaciones</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="openEmployeeVacations(props.row)"
                    >Ver vacaciones</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import AddEmployeeVacationsModal from "./AddEmployeeVacationsModal";
import EmployeeVacations from "./EmployeeVacations.vue";

export default {
  name: "EmployeeVacationsList",
  props: [],
  components: {},
  data() {
    //Proveedores
    return {
      perPage: 50,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: this.loadColumnsFromLocalStorage(
        [
          {
            field: "NOMBRE",
            label: "Nombre",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "PATERNO",
            label: "Apellido paterno",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "MATERNO",
            label: "Apellido materno",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "ANTIGUEDAD",
            label: "Años de antiguedad",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "DIAS_VACACIONES",
            label: "Días de vacaciones",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "DIAS_VACACIONES_RESTANTES",
            label: "Días de vacaciones restantes",
            sortable: true,
            searchable: true,
            display: true,
          },
        ],
        "employee-vacations-list-columns"
      ),
      moment: moment,
      startDate: moment().startOf("year").toDate(),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETEMPLOYEES"));
      allPromises.push(
        this.$store.dispatch("GETVACATIONS", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        })
      );

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    async changeFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(this.$store.dispatch("GETEMPLOYEES"));
        allPromises.push(
          this.$store.dispatch("GETVACATIONS", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
          })
        );

        await Promise.all(allPromises);
      }
    },
    openAddEmpoloyeeVacations(employeeInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddEmployeeVacationsModal,
        props: {
          employeeInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openEmployeeVacations(employeeInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: EmployeeVacations,
        props: {
          employeeInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    columns: {
      handler(newColumns) {
        this.saveColumnsToLocalStorage(
          newColumns,
          "employee-vacations-list-columns"
        );
      },
      deep: true,
    },
  },
  computed: {
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        const employeeWithProps = { ...singleEmployee };

        if (singleEmployee.TIPO === "CO") {
          employeeWithProps.TIPO_FOR = "Comprador";
        } else if (singleEmployee.TIPO === "VE") {
          employeeWithProps.TIPO_FOR = "Vendedor";
        } else if (singleEmployee.TIPO === "VC") {
          employeeWithProps.TIPO_FOR = "Vendedor/Comprador";
        }

        let now = moment(new Date());
        let end = moment(singleEmployee.FECHA_INICIO);
        let duration = moment.duration(now.diff(end));
        let years = duration.asYears();

        employeeWithProps.ANTIGUEDAD = parseInt(years);

        employeeWithProps.DIAS_VACACIONES = 0;

        if (parseInt(years) === 1) {
          employeeWithProps.DIAS_VACACIONES = 12;
        }

        if (parseInt(years) === 2) {
          employeeWithProps.DIAS_VACACIONES = 14;
        }

        if (parseInt(years) === 3) {
          employeeWithProps.DIAS_VACACIONES = 16;
        }

        if (parseInt(years) === 4) {
          employeeWithProps.DIAS_VACACIONES = 18;
        }

        if (parseInt(years) === 5) {
          employeeWithProps.DIAS_VACACIONES = 20;
        }

        if (parseInt(years) > 5 && parseInt(years) <= 10) {
          employeeWithProps.DIAS_VACACIONES = 22;
        }

        if (parseInt(years) > 10 && parseInt(years) <= 15) {
          employeeWithProps.DIAS_VACACIONES = 24;
        }

        if (parseInt(years) > 15 && parseInt(years) <= 20) {
          employeeWithProps.DIAS_VACACIONES = 26;
        }

        if (parseInt(years) > 20 && parseInt(years) <= 25) {
          employeeWithProps.DIAS_VACACIONES = 28;
        }

        if (parseInt(years) > 25 && parseInt(years) <= 30) {
          employeeWithProps.DIAS_VACACIONES = 30;
        }

        if (parseInt(years) > 30 && parseInt(years) <= 35) {
          employeeWithProps.DIAS_VACACIONES = 32;
        }

        employeeWithProps.DIAS_VACACIONES_RESTANTES =
          employeeWithProps.DIAS_VACACIONES;

        employeeWithProps.VACACIONES = [];

        for (const singleVacation of this.vacations) {
          if (
            singleVacation.PERSONAL.toString() === singleEmployee._id.toString()
          ) {
            if (singleVacation.STATUS !== "C") {
              employeeWithProps.DIAS_VACACIONES_RESTANTES -=
                singleVacation.DIAS;
            }

            employeeWithProps.VACACIONES.push(singleVacation);
          }
        }

        return employeeWithProps;
      });
    },
    vacations() {
      return this.$store.getters.VACATIONS.map((singleVacation) => {
        return singleVacation;
      });
    },
  },
  created() {
    moment.locale("es");
  },
};
</script>

<style scoped>
@import "../style/EmployeesList.css";
@import "../../Global/style/Global.css";
</style>
